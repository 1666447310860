@import "../../assets/styles/global.scss";
.navbar {
  border-bottom: #ebebeb solid 1px;
  .container-fluid {
    padding: 0px 0px;
    .navbar-nav {
      gap: 32px;
      .nav-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-weight: 400;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        color: #807f7f;
        margin: 0px 0px;
        padding: 8px 0px;
        &:active,
        &:focus {
          outline: none !important;
          font-weight: 500;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          color: #000;
        }
      }

      .nav-indicator {
        width: 42px;
        height: 3px;
        margin: 0px 0px -1px 0px;
        background: $primary-color;
        border-radius: 50px;
      }
    }

    .dropdown {
      .dropdown-menu {
        right: 0 !important;
        left: auto !important;
        width: 24rem;
        padding: 16px 28px;
        border-radius: 16px;

        .filters-field-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 8px;
          .filters-field {
            font-size: 12px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            border-radius: 8px;
            height: 32px;
            padding: 4px;
            background-color: #EEF5FD;
            border: none;
            border: 2px solid #88BFDD;

            &:focus {
              outline: none;
              // border: 2px solid rgb(59, 107, 126);
            }
          }
        }

        .drop-down {
          font-size: 12px;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          border-radius: 8px;
          height: 32px;
          padding: 4px;
          background-color: #EEF5FD;
          border: none;
          border: 2px solid #88BFDD;

          &:focus {
            outline: none;
            // border: 2px solid rgb(59, 107, 126);
          }
        }

        .label-heading {
          font-size: 16px;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: #000;
        }

        .accordion .accordion-heading{
          font-family: Roboto, sans-serif;
        }
      }
      .filter-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-weight: 400;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        color: #807f7f;
        margin: 0px;
        padding: 8px 0px;
        &:focus {
          outline: none !important;
        }
      }
    }
  }
}
