@import "../../assets/styles/global.scss";
.heading {
  display: flex;
  flex-direction: column;
  p:nth-child(2) {
    font-size: 0.9rem;
    font-weight: 300;
    font-family: Roboto Flex, sans-serif;
    color: #9c9faa;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  .btn:active {
    background-color: $primary-color;
    color: #fff;
  }

  button {
    background-color: #fff;
    border: 1px solid $border-color;
    color: $text-color-light;
    padding: 4px 8px;

    &:active {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

.p {
  font-size: 1rem;
  font-weight: 600;
  font-family: Roboto Flex, sans-serif;
  color: #1e1f23;
  margin: 4px 0px;
  padding: 0px;
}
