@import "../../assets/styles/global.scss";

.cont {
  display: flex;
  .card-body {
    &.shadow {
      box-shadow: 2px 2px 12px 2px #eee;
    }

    // .card-body > div:nth-child(2) {
    //   border-radius: 5px;
    // }

    border-color: $border-color;
    .two-panel-flex {
      display: flex;
      gap: 20px;
      padding: 2rem 0.5rem;

      .panel-content-container {
        border: 1px solid $border-color;
        overflow-x: hidden;
        overflow-y: auto;
        .panel-tab-heading {
          color: $text-color;
          background-color: #F8F8F8;
          border-bottom: 0.5px solid $border-color;
          text-align: center;
          margin: 0px;
          padding: 0px;
          .panel-heading-content {
            padding: 13px;
            margin: 0px;
            font-family: Roboto Flex, sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
            letter-spacing: 0.01em;
            text-align: left;
          }
        }
        .panel-tab-content {
          padding: 1rem;
          .btn {
            cursor: pointer;
            width: 13rem;
            margin-left: 0.5rem;
            color: #e36f1e;
            background-color: white;
            border: 1px solid #e36f1e;
            padding: 0.5em;
            border-radius: 0.3em;
          }
          .btn:hover {
            color: white;
            background-color: #e36f1e;
            opacity: 1;
          }

          .tabs {
            display: flex !important;
            .active {
              cursor: default;
              width: 13rem;
              font-size: 1rem;
              color: white !important;
              background-color: #162e4a !important;
              border: 1px solid #162e4a !important;
            }
            .active:hover {
              opacity: 1;
            }
          }
        }
        .table-container {
          background-color: transparent;
          .form-content {
            display: flex;
            flex-grow: 1;
            position: relative;
            overflow: hidden;
            .list-container {
              .card-header {
                .btn-circle {
                  border: none;
                  border-radius: 50%;
                  width: 3rem;
                  height: 3rem;
                }
              }
              .datatable-container {
                min-height: 15rem;
                .datatable-wrapper {
                  position: relative;
                  clear: both;
                  zoom: 1;
                  .datatable-header {
                    padding: 1.25rem 1.25rem 0 1.25rem;
                    margin: 0 0 1.25rem 1.25rem;
                    .float-right {
                      float: right !important;
                      display: inline-flex;
                    }
                    .datatable-filter {
                      float: right;
                      text-align: right;
                      position: relative;
                      display: block;
                      margin: 0 0 1.25rem 1.25rem;
                    }
                  }

                  .table {
                    position: static;
                    zoom: 1;
                    border-bottom: none !important;
                    margin: 0 auto;
                    border-collapse: separate;
                    border-spacing: 0;
                  }
                  .datatable-footer {
                    padding: 1.25rem 1.25rem 0 1.25rem;
                    border-top: none !important;
                    .dataTables_info {
                      clear: both;
                      float: left;
                      padding: 0px 10px;
                      margin-bottom: 1.25rem;
                    }
                    .dataTables_paginate {
                      float: right;
                      text-align: right;
                      padding-top: 0.25em;
                      margin: 0 0 1.25rem 1.25rem;
                      .paginate_button {
                        cursor: default;
                        color: #666 !important;
                        border: 1px solid transparent;
                        box-shadow: none;
                        padding: 3px 8px;
                        margin: 0px 3px;
                        box-sizing: border-box;
                        display: inline-block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
