@import "../../assets/styles/global.scss";

.filterdropdown{

.filter-button {
    background-color: $primary-color;
    color: $color-white;
    border: 1px solid $primary-color-dark;
  }
  
  .filter-dropdown-menu {
    background-color: $primary-color-light;
    border: 1px solid $neutral-light-gray;
    color: $text-color;
  }
  
  .filter-group {
    margin-bottom: 1rem;
  }
  
  .label-heading {
    font-weight: bold;
    color: $text-color;
    margin-bottom: 0.5rem;
  }
  
  .drop-down,
  .input-field {
    padding: 0.5rem;
    border: 1px solid $border-color;
    border-radius: 4px;
    color: $text-color;
  }
  
  .drop-down:focus,
  .input-field:focus {
    outline: none;
    border-color: $primary-color;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  .btn-apply {
    background-color: $primary-color;
    color: $color-white;
    border-radius: 6px;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
  }
  
  .btn-apply:hover {
    background-color: rgb(62, 119, 73);
  }
  
  .btn-reset {
    background-color: rgb(251, 84, 84);
    color: $color-white;
    border-radius: 6px;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
  }
  
  .btn-reset:hover {
    background-color: $neutral-gray;
  }
  
}