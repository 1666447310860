.tooltip-wrapper {
  position: relative;
  display: initial;
//   border-bottom: 1px dotted black;
  .tooltiptext {
    visibility: hidden;
    // position: absolute;
    position: fixed;
    width: 120px;
    background-color: rgba(85,85,85,0.85);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.6s;
  }
//   &:hover .tooltiptext {
//     visibility: visible;
//   }
  .tooltip-top {
    // bottom: 125%;
    // left: 50%;
    // margin-left: -60px;
    margin-left: -40px;
    margin-top: -30px;
  }
}

.tooltip-wrapper:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
