@import "../../assets/styles/global.scss";

.dashboard {
  font-family: Roboto Flex , sans-serif;

  
  .monthly-report-card {
    padding: 15px 15px ;
    border-radius: 6px;
    border: 0.8px solid $border-color;
    
    .monthly-report-title {
 
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      text-align: left;
      color:$text-color;
      
      }

      .monthly-report-child {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-top: 10px;
      }
      .monthy-report-heading {
 
        height: 14px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.06px;
        text-align: left;
      }

      .monthy-report-data {

        height: 14px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.06px;
        text-align: left;
      }
      .status {
        width: 100%;
        height: 5px;
        border-radius: 8px;
      }
  }

  .row-container {
    // max-height: 300px;
    // height: 26.8rem;
    .itemContainerLast {
      border-radius: 12px;
      padding: 16px;
  
    }
  }
  
  .sector-buttons {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }
  
  .sector-buttons .active {
    font-weight: 700;
    color: #4b4279;
  }
  
  
  
  @media (max-width: 1200px) {
    .margin {
      margin-bottom: 16px;
    }
  }
}




// .main {
//   .charts {
//     display: grid;
//     gap: 20px;
//     padding: 20px;
//     grid-template-columns: repeat(4, 1fr);
//     grid-auto-rows: 250px;
//     justify-content: space-evenly;

//     .content {
//       box-shadow: 0 10px 15px -5px rgba(150, 170, 180, 0.5);
//       padding: 15px 10px;
//       border-radius: 10px;

//       &:nth-child(5) {
//         grid-column: 1 / span 2;
//       }
//       &:nth-child(6) {
//         grid-column: 3/-1;
//         grid-row: 2 / span 2;
//       }
//     }
//   }

//   @for $i from 1 through 17 {
//     .sdg-#{$i} #sdg-#{$i} > path {
//       fill: #ccc;
//     }
//   }
// }

// .project_filter_wrapper {
//   width: 80%;
//   margin-left: 25px;
//   @media screen and (max-width: 990px) {
//     width: 100%;
//     margin-top: 15px;
//   }
// }

// .chart-bg-new {
//   height: 400px;
//   background-color: #fff;
// }

// .chart-bg-new2 {
//   height: auto;
//   background-color: #fff;
// }

// .pakistan_map {
//   height: 505px;
//   border: 2px solid #000;
// }

// .filter-box {
//   display: flex;
//   flex-direction: row;
//   gap: 1rem;

//   .filter {
//     display: inline-flex;
//     flex-direction: row;
//     gap: 0.5rem;

//     label {
//       font-weight: bold;
//       margin-bottom: 0.5rem;
//     }

//     // select {
//     //   padding: 0.5rem;
//     //   border: 2px solid #ccc;
//     //   border-radius: 0.25rem;
//     //   background-color: #fff;
//     //   color: #333;
//     //   font-size: 1rem;
//     //   font-family: Arial, sans-serif;
//     //   cursor: pointer;
//     //   transition: border-color 0.3s ease-in-out;

//     //   &:focus,
//     //   &:hover {
//     //     border-color: #007bff;
//     //     outline: none;
//     //   }

//     //   option {
//     //     background-color: #fff;
//     //     color: #333;
//     //     font-size: 1rem;
//     //     font-family: Arial, sans-serif;
//     //   }
//     // }
//   }
// }

// select {
//   padding: 0.5rem;
//   border: 2px solid #ccc;
//   border-radius: 0.25rem;
//   background-color: #fff;
//   color: #333;
//   font-size: 1rem;
//   font-family: Arial, sans-serif;
//   cursor: pointer;
//   transition: border-color 0.3s ease-in-out;

//   &:focus,
//   &:hover {
//     border-color: #007bff;
//     outline: none;
//   }

//   option {
//     background-color: #fff;
//     color: #333;
//     font-size: 1rem;
//     font-family: Arial, sans-serif;
//   }
// }

// .main_circle {
//   display: inline-block;
//   background: #a9a9a95c;
//   /* background: linear-gradient(90deg, rgba(255,115,0,1) 0%, rgba(233,109,52,1) 66%, rgba(121,26,9,1) 100%, rgba(255,201,0,1) 100%);     */
//   padding: 15px;
//   border-radius: 50%;
//   text-align: center;
//   /* border: 3px solid #db8800; */
//   box-shadow: 0 0 20px 0px #6b6969;
// }
// .main_circle > .content {
//   padding: 20px 35px;
//   border-radius: 50%;
//   background: #fff;
//   border: 20px solid #f1f1f1;
// }

// .bg-InputFields {
//   background: #f1f1f1;
//   padding: 18px;
//   border-radius: 10px;
// }
