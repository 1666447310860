@import "../../assets/styles/global.scss";

.noc-layout {
  .noc-layout-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .extension-btn:active {
      background-color: $primary-color-dark;
      border: 1px solid $primary-color;
      box-shadow: 0px 4px 10.3px 0px $secondary-color;
      color: $color-white;
    }

    .extension-btn:hover {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      box-shadow: 0px 4px 10.3px 0px $secondary-color;
      color: $color-white;

      .icon {
        fill: #fff;
        transition: fill 0.3s ease;
        .svg {
          .path {
            fill: #fff;
          }
        }
      }
    }

    .project-name {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      text-align: left;
      color: $text-color;
    }

    .organization-name {
      font-size: 14px;
      font-weight: 300;
      line-height: 19.6px;
      text-align: left;
      color: $text-color-light;
    }

    .submit-btn {
      color: $color-white;
      background-color: $primary-color;
    }
  }

  .extension-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $text-color;
    border: 1px solid $primary-color;
    border-radius: 4px;
    width: 250px;
    height: 39px;
    padding: 10px 15px 10px 15px;
    gap: 4px;

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: $text-color;
    }
  }
}
