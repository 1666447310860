@import "../../assets//styles/global.scss";
.wrap {
  background-color: #fff;
  // background-color: #F8F9FA;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  width: 18rem;
  gap:2rem;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 6rem;
    gap: .5rem;

    img {
      max-width: 67px;
    }

    p{
      font-size: 1.5rem;
      font-weight: 900;
      color: $primary-color;
      font-family: Roboto Flex, sans-serif;
      line-height: 28px;
      margin: 0px;
    }
  }

  .mobileLogo {
    display: none;
  }

  .line1 {
    height: 2px;
    // margin: 1rem 0;
    color: inherit;
    background-color: #000000;
    border: 0;
    opacity: 0.25;
  }

  .items {
    margin-bottom: auto;
    flex-direction: column;
    display: flex;
    padding-left: 0.7rem;
    margin-top: 0;
    color: #000;

    .nav-link {
      list-style: none;
      padding: 0px;
      display: flex;
      align-items: center;
      background-color: #f8f9fa;
      color: #000;
      position: relative;
      flex-wrap: wrap;

      .active {
        background-color: #fff;
        width: 100%;
        color: #000;
        border-radius: 2rem;
        padding: 1em;

        &::after,
        &::before {
          content: " ";
          position: absolute;
          top: -50px;
          right: 0;
          width: 20px;
          height: 50px;
          border-radius: 0 0 25px 0;
        }

        .child-items {
          display: inherit;
        }
      }

      a {
        color: #000;
        padding: 1rem 0.7rem;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          padding-right: 1rem;
        }
      }

      &.active.has-children {
        background-color: #000;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        > .active {
          color: #f8f9fa;
        }
      }

      &.active {
        .child-items {
          display: block;

          li.active {
            padding: 0rem 1em 1rem;
          }

          .active {
            // padding: 0.7rem 1em;
            background-color: transparent;

            &:before,
            &:after {
              display: none;
            }
          }
        }
      }
    }

    &.child-items {
      display: none;
      padding: 0;

      .nav-link {
        background-color: #fff !important;

        // padding: 0 1em 1rem !important;
        // border-radius: 2rem 0 0 2rem;
        .active {
          border-radius: 0;
          background-color: transparent;
        }

        a {
          padding: 0;
          color: initial;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .wrap {
    width: 70px;
    padding: 0;

    .logo {
      display: none;
    }

    .mobileLogo {
      display: contents;

      img {
        margin: 8px;
        width: 50px;
      }
    }

    .line1 {
      display: none;
    }

    .items {
      padding: 0 0 0 6px;

      .nav-link {
        a {
          padding: 0.5rem;

          .title {
            display: none;
          }
        }
      }
    }
  }
}

.active {
  font-weight: bold !important;
}

@media screen and (max-width: 767px) {
  .wrap {
    width: 50px;
  }

  .wrap .mobileLogo img {
    width: 35px;
  }

  .wrap .items .nav-link a span {
    padding-right: 0;
  }

  .wrap .items .nav-link .active::after,
  .wrap .items .nav-link .active::before {
    width: 0;
  }

  .wrap .items .nav-link .active {
    padding: 0.7em;
  }
}

.icon-bg-new {
  background-color: #e4701e;
}

.sidebar {
  // margin-left: 35px;
  background-color: #fff !important;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;

  .sidebar-heading{
    font-weight: 400;
    font-size: 14px;
    color:$text-color-light;
  }

  // padding: 20px;
  .sidebar-nav {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 8px;

    .sidebar-text {
      margin-left: 10px;
      color: $text-color-light;
      font-size: 16px;
    }

    .icon-bg {
      width: 35px;
      height: 35px;
      // background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      .sidebar-icon {
        font-size: 17px;
        color: #67748e;
      }
    }

    &:hover {
      background: $primary-color 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #094fdc24;
      border-radius: 10px;
      transition: 0.2s ease-in-out;

      .sidebar-text{
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        // letter-spacing: 0.5px;
      }

      .icon-bg {
        // background-color: $primary-color;
        transition: 0.2s ease-in-out;

        .sidebar-icon {
          color: #fff;
        }
      }
    }
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-40px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .sidebar-child {
    opacity: 0;
    margin-left: 55px;
    transform-origin: top center;
    animation: slideDown 250ms ease-in-out forwards;

    .sidebar-child-text {
      color: #67748e;
      font-size: 15px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

.active-sidebar-link {
  background: $primary-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #67748e24;
  border-radius: 10px;
  transition: 1s ease-in-out !important;

  .sidebar-text {
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
    // font-weight: bold !important;
    // letter-spacing: 0.5px;
  }

  .icon-bg {
    // background-color: #e4701e !important;
    // transition: 0.2s ease-in-out;

    .sidebar-icon {
      color: #fff !important;
    }
  }
}

.passive-sidebar-nav {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px 5px;
  cursor: pointer;
  margin-top: 20px;

  .icon-bg {
    width: 35px;
    height: 35px;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    .sidebar-icon {
      font-size: 24px;
      color: #67748e;
    }
  }
}

.toggle-btn-new {
  margin-right: -30px;
  z-index: 999999;
  .inside-btn-toggle {
    margin-left: auto;
    background: #F8F9FA;
    padding: 3px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #F8F9FA;
    cursor: pointer;
  }
}
