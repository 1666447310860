@import "../../assets/styles/global.scss";
.table-style {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .table-wrap {
    width: 100%;
    th {
      min-width: 140px;
      background-color: transparent;
    }

    th.src {
      min-width: 80px;
    }
    th.ri {
      min-width: 240px;
    }

    th {
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }

    .result-render {
      p {
        margin: 0;
        transform: (rotate(-90deg));
      }
    }

    .form-control {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
  }
}
