@import "../../assets/styles/global.scss";
.extension-detail {


  .btn-forward{
    background-color: $primary-color;
    color: #fff;
    padding: 10px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    &:hover {
      background-color: $primary-color-dark;
    }
  
  }
  .btn-backward{
    background-color: #fff;
    color: $text-color;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid $text-color;
    cursor: pointer;
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }
  .label {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: $text-color;
    font-weight: 700;
  }

  .bordered-row {
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: 2px;
    padding: 12px 16px;
    color: $text-color;
    font-family: Roboto, sans-serif;
    font-size: 14px;

    :nth-child(1) {
      color: $neutral-light-gray-8;
    }
  }

  .header-child {
    margin-bottom: 7px;
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    color: $text-color;
    font-weight: 600;
    line-height: 19.6px;
  }

  .underlined_text {
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    font-weight: 400;
    // underline
    text-decoration: underline;
    color: #0e5893;
  }
  
}

.download-button {
  padding: 4px 16px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  gap: 4px;
}