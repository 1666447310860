@import "../../assets//styles//colors.scss"; 


.active{
    color:$primary-color;
    font-weight: bold;
}

// .searchList a.active{
//     // text-decoration: underline!important;
// }

