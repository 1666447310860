.progress-container {
  background-color: transparent;
  padding: 10px 0;
  z-index: 1000;
  position: relative;
  display: flex;
  justify-content: center;

  &.sticky {
    position: fixed;
    top: 0;
    width: 82%;
    background-color: white;
    justify-content: center;
  }
}

.progress-line {
  height: 1px;
  background-color: gray;
  width: 85%;
  position: absolute;
  top: 32px;
  z-index: -1;
  margin: 0 auto;

  &.sticky-i{
    width: 70%;
  }
}

.steps {
  display: flex;
  justify-content: space-around;

  .step {
    padding: 10px 16px;
    font-weight: 400;
    font-size: 1rem;
    color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.active-i {
      color: green;
    }

    .step-outline {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-color: white;
      border: 1px solid gray;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active-i {
        border-color: green;
      }

      .step-inner {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background-color: white;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active-i {
          background-color: green;
        }
      }
    }
  }
}
