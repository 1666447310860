@import "../../assets/styles/global.scss";
.wrapper {
  &.overlay {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(5, 5, 5, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999;
    &.light {
      background: rgba(255, 255, 255, 0.2);
      color: #000;
    }
  }

  .loader-inner {
    position: relative;
    display: flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    border: 8px solid rgba(#fff, 0.3);
    border-radius: 50%;
    border-top-color: $primary-color;
    animation: spin 1s linear infinite;

    .img-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      animation:rev 1s linear infinite;
    }

    .img-logo {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

@keyframes rev {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}