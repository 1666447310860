@import "../../../assets/styles/global.scss";
.detail-container {
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: 8px;

  .body-text{
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .body-heading{
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
}
.label-heading {
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  color: $text-color;
  font-weight: 700;
  margin: 8px 0;
}
