@import "./../../assets/styles/global.scss";

.form-stepper {
  .step-wizard {
    height: 84px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    border-radius: 8px;

    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .stepper-content {
    // box-shadow: 0px 8px 15px #67748E24;
    padding: 10px 0px 25px;
    // border-radius: 6px;
    position: relative;
    // background-color: #fff;
  }

  .step-wizard-list {
    color: #d3d3d3;
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px 10px;
    position: relative;
    z-index: 10;
    overflow: auto;
    gap: 24px;
  }

  .step-wizard-item-d {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 12%;
    max-width: 15%;
  }
  .progress-count {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    // margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  .progress-count:after {
    content: "";
    height: 40px;
    width: 40px;
    // background: #0175b6;
    background: #e36f1e;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
  }

  .progress-label {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto Flex, sans-serif;
  }

  .current-label {
    color: #fff;
  }

  .current-item ~ .step-wizard-item .progress-label {
    opacity: 0.5;
  }

  .progress-count:after {
    background: #f8f9fa;
    border: 2px solid $light-gray-color;
  }

  .current-item {
    .progress-label {
      color: #fff;
    }
  }

  .stepper-action {
    text-align: right;
    margin: 20px 6px 0;

    button {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      border: none;
      color: white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px auto;
      border-radius: 6px;
      margin-left: 10px;
      min-width: 150px;
      cursor: pointer;
      transition: 0.5s all;
      &:hover {
        background-color: $primary-color-dark;
      }
      &.btn-primary {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
          background-color: $primary-color-dark;
        }
      }
      &.btn-outline {
        background-color: transparent !important;
        color: $primary-color;
      }
      &:disabled {
        background-color: $light-gray-color;
        border-color: $light-gray-color;
        cursor: not-allowed;
      }
    }
  }
}
