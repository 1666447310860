@import "../../assets/styles/global.scss";
.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border: 2px solid $border-color;
    color: $neutral-light-gray-8;
    padding: 4px 8px;
    border-radius: 6px;
    font-family: Roboto Flex, sans-serif;
    &:active,&.active {

      background-color: rgba($primary-color, 0.2);
      border: 2px solid $primary-color;
      outline: none;
      font-family: Roboto Flex, sans-serif;
      .text-regular {
        color: $primary-color;
        font-family: Roboto Flex, sans-serif;
         /* Direct primary color for text */
      }
    }
  }
  .regularButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    color: $color-white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-family: Roboto Flex, sans-serif;
    .text-regular {
      color: $color-white;
      font-family: Roboto Flex, sans-serif;
    }
  }
}
