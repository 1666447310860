$primary-color: #075f33;
$primary-color-dark: #01411c;
$secondary-color: #1e1f23;
$text-dark-color: #000;

$info-color: #1b7ed9;
$success-color: #075f33;
$warning-color: #f3be02;
$error-color: #d71a01;

$neutral-gray: #9e9e9e;
$neutral-light-gray: #dcdcdc;
$neutral-light-gray-8: #8e8e8e;
$neutral-light-gray-9: #909090;

$color-white: #fff;

$light-gray-color: #ccc;
$primary-color-light: #f8f9fa;
$text-color: $secondary-color;
$text-color-light: $text-dark-color;
$link-color: $primary-color;
$border-color: $neutral-light-gray;
$table-header-bg: #f8f8f8;
