.loader-text {
  font-size: 1rem;
  font-weight: 500;
  color: gray;
  margin-top: 1rem;
  text-align: center;
  display: block;
  margin-bottom: 1rem;
  font-family: "Roboto Flex";
}

.custom-border {
  border: 1px solid #000;
  border-radius: 8px;
  border-style: dashed;
}

.drag-over {
  border-color: #007bff;
  background-color: #e7f0ff;
}