.ReactModal__Overlay .ReactModal__Content {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  width: 500px;
}

.conf-mod {
}
