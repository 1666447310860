@import "../../assets/styles/global.scss";

.dashboard-card{
padding: 10px;
color:black;
border-radius: 10px;
height: 115px;
font-family: Roboto Flex , sans-serif;


.heading{
    display: flex;
     padding-left: 18px;
     padding-top: 12px;
    //  font-size: 14px;
     line-height: 19px;
     font-weight: 400;
 }

 .data{
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .data-value{
      
        border-radius: 5px;
        display: flex;
        font-size: larger;
        font-weight: bold;
        justify-content: start;
        align-items: center;
        padding-left:10px;
    }

    .data-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
    }
}
.footer{
    font-size: x-small;
    display: flex;
    flex-direction: row;
    align-items: center;   

    .text{
        padding: 5px;
        width: 100px;
        display: flex;
        justify-content: center;
       
    }
  
    .value{
        width: 50px;
        border-radius: 3px;
       background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
       
      }
}
}

