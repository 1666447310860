@import "../../assets/styles/global.scss";

.nav-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 20px;
  border: 0.5px solid $border-color;
  border-radius: 4px;
  color: $text-color;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    background-color: $primary-color;
    border: 0.5px solid $primary-color;
    box-shadow: 0px 4px 10.3px 0px $secondary-color;
    color: $color-white;

    .nav-button-icon {
      fill: white;
    }
  }

  &:hover {
    background-color: #075f3312;
    border: 0.5px solid $border-color;
    box-shadow: 0px 4px 10.3px 0px $secondary-color;
    color: $primary-color;

    .nav-button-icon {
      fill: $primary-color;
    }
  }
}

.nav-button-icon {
  fill: $primary-color;
  transition: fill 0.3s ease;
}

.nav-button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  width: 60px;
  height: 40px;
}
