.countdown {
  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    // padding: 2px 0px 0px 3px;
    // gap: 4px;
    .seprator-line {
      // width: 80%;
      // height: 1px;
      // background-color: #313131b0;
      // margin: 0px 4px;
      // position: absolute;
      // z-index: 1;
    }
  }
  .box {
    width: 280px;
    // width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    // margin-left: 4px;
    // height: 60px;
    // max-width: 60px;
  }

  .value {
    font-size: 16px;
    font-weight: 500;
    background-color: #144b40;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 20px;
    height: 20px;
    position: relative;
    box-shadow: -1px 0px 2px 0px #232423ad;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      z-index: 2;
      background-color: #575757b0;
      //   box-shadow: 0px 0px 1px 0px #2e2f2ed6;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      left: -4px;
      width: 6px;
      height: 10px;
      background-color: #969696;
      box-shadow: inset -1px 1px 5px 1px #0e0e0ead;
    }
  }

  .label {
    font-size: 16px;
    font-weight: 100;
  }
}

@media screen and (min-width: 550px) {
  .countdown {
    .content {
      gap: 0px;
    }

    .value {
      font-size: 16px;
      width: 30px;
      height: 30px;
    }

    .box {
      height: 36px;
      max-width: 36px;
    }
  }
}

@media screen and (min-width: 790px) {
  .countdown {
    .value {
      font-size: 16px;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      // box-shadow: -1px 0px 2px 1px #232423ad;
    }

    .box {
      max-width: 36px;
      height: 36px;
    }
  }
}
