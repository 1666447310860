@import "../../../assets/styles/global.scss";
.label-heading {
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  color: $text-color;
  font-weight: 700;
  margin: 8px 0;
}

.upload-btn {
  padding: 4px 16px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  border: none;
  gap: 8px;
}


.download-btn {
  padding: 4px 16px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  border: none;
  gap: 8px;

  &:hover {
    color:white;
  }
}

.general-text{
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.label-text {
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  color: $text-color;
  font-weight: 400;
}

.btn-forward{
  background-color: $primary-color;
  color: #fff;
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  &:hover {
    background-color: $primary-color-dark;
  }

}
.btn-backward{
  background-color: #fff;
  color: $primary-color;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid $primary-color;
  cursor: pointer;
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  &:hover {
    background-color: $primary-color;
    color: #fff;
  }
}