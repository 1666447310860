.custom-notification-bar {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    padding: 10px;
    background-color: #fff;
    transition: all 0.3s ease;
    border-radius: 5px;


    .notification-status {
        display: flex;
        background-color: rgba(11, 164, 57, 0.13);
        padding: 10px;
        border-radius: 100%;
        margin-right: 10px;
    }

    .notification-text {
        font-weight: 600;
        font-size: 12px;
    }

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}