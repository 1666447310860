@import "../../assets/styles/global.scss";

.top-header-detail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 16px;
  line-height: 22.4px;
  color: $text-color-light;
}
.monthly-report-detail{
  .btn-forward{
    background-color: $primary-color;
    color: #fff;
    padding: 10px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    &:hover {
      background-color: $primary-color-dark;
    }
  
  }
  .btn-backward{
    background-color: #fff;
    color: $text-color;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid $text-color;
    cursor: pointer;
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .body {

    .project-detail {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .project-detail-heading {
        width: fit-content;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        color: $text-color;
      }

      .project-detail-data {
        height: 90%;
        border: 0.5px solid $border-color;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px 15px;

        .bold-header {
           
          font-weight: 400;
          color: $text-color;
          font-size: 14px;
        }

        .text-data {
          font-weight: 400;
          color: $text-color-light;
        }
      }
    }

    .project-report {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .project-report-heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        width: fit-content;
        height: 20px;
        color: $text-color;
      }

      .project-report-data {
      height:90%;
        border: 0.5px solid $border-color;
        border-radius: 4px;
      }

      .project-report-data-header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        border-bottom: 0.5px solid $border-color;
        border-radius: 4px;
        background-color: $primary-color-light;
        color: $text-color;
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        letter-spacing: 0.01em;
      }

      .project-report-data-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        color: $text-color;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: 0.01em;
        font-weight: bold;
      }
    }
  }

  .btn-text{
font-size: 14px;
font-weight: 400;
line-height: 19.6px;

  }
}
