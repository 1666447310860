@import "../../assets/styles/global.scss";
.funds-details {
  .label {
    font-family: Roboto Flex, sans-serif;
    font-size: 16px;
    color: $text-color;
    font-weight: 700;
  }

  .header-name {
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: 2px;
    padding: 12px 16px;
    p:nth-child(1) {
      margin: 0;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: $text-color;
    }
    p {
      margin: 0;
      font-family: Roboto, sans-serif;
      font-size: 14px;
    }
  }

  .header-child {
    margin-bottom: 7px;
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    color: $text-color;
    font-weight: 600;
    line-height: 19.6px;
    
  }

  .underlined_text {
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    font-weight: 400;
    // underline
    text-decoration: underline;
    color: #0e5893;
  }
}
