
@import "../../../assets/styles/global.scss";
.btn-wrapper{
    justify-content: center;
    align-items: end;
    display: flex;
    // padding-right: 2em;
    > div{
        width: 100%;
        display: flex;
    }
    .btn{
        color: #fff;
        line-height: 2em;
        width: 100%;
        border-radius: 6px;
        background:$primary-color;
        border:none;
        &:hover{
            cursor: pointer;
        }
    }
}
