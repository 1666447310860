@import "../../assets/styles/global.scss";
@import "../../assets//styles/colors.scss";

.notification-page {
  // my-3 p-4 rounded d-flex flex-column gap-2 col-md-12 justify-content-center align-items-center
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100vh;

  .text-gray {
    color: $neutral-gray;
  }

  .header {
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    padding: 2px;
    font-family: Roboto Flex Flex;
  }

  .notifications {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    padding: 20px;
    border-radius: 8px;
    gap: 2px;
    background-color: $color-white;
    overflow-y: auto;

    .notification-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border-bottom: 0.5px solid $border-color;
      padding: 10px;

      .notification-user-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .data {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $color-white;
          font-size: 13px;
          font-weight: 700;
        }
      }

      .notification-message {
        // d-flex flex-column
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        overflow-y: auto;

        .notification-message-data {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          text-align: justify;
          color: $text-color;
        }

        .notification-message-time {
          font-size: 11px;
          font-weight: 400;
          line-height: 20px;
          color: $text-color-light;
        }
      }
    }
  }
}