@import './../../assets/styles/colors.scss';

.rdt_ExpanderRow {
    margin-left: 30px;
}

a {
    color: $text-color;

    &:hover {
        text-decoration: none;
    }
}

.btn-primary {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 4px;
    background-color: $primary-color;
    border: 1px solid $primary-color;

    &:hover {
        background-color: $primary-color-dark;
    }
}

.rdt_Table {
    .rdt_TableRow {
        .rdt_TableCell {
            input[type="checkbox"] {
                height: 20px;
                width: 20px;
            }
        }
    }

    .rdt_TableHead {
        .rdt_TableCol {
            input[type="checkbox"] {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.rdt_Pagination {
    select {
        padding-top: 0;
        padding-bottom: 0;
    }
}