@import "../../../assets/styles/global.scss";
.upload-button{
    padding: 4px 16px;
    background-color: $primary-color-light;
    display:flex !important;
    align-items: center;
    justify-content: center;
    border-radius:4px;
    cursor: pointer;
    border:1px solid $neutral-light-gray-8;
    color:#000;
    gap: 5px;
    margin-top: 6px !important;
}