@import "../../assets/styles/global.scss";

textarea::placeholder {
  font-style: italic;
}



.comment-section {
  border-top: 0.5px solid $border-color;
  border-left: 0.5px solid $border-color;
  border-bottom: 0.5px solid $border-color;
  display: flex;
  flex-direction: column;
  height: 750px;
  background-color: $color-white;

  .comment-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-bottom: 0.5px solid $border-color;

    .comment-header-data {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      cursor: pointer;
      margin: 0px 0px 0px 24px;
    }
  }

  .comment-body {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    // border-bottom: 0.5px solid $border-color;

    .comment-options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 0.5px;
      padding-right: 0.5px;
      height: 30px;
      border-radius: 4px;
      color: $text-color;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      cursor: pointer;

      .comment-options-child {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid $border-color;

      }
      .selected{
        background-color: $primary-color;
        color: #fff;
        border: 1px solid $primary-color;
      }

      .right {
        border-radius: 0px 4px 4px 0px;
      }

      // .comment-options-child.active {
      //   background-color: $light-gray-color;
      // }

      // .comment-options-child:hover {
      //   background-color: $light-gray-color;
      // }
    }

    .comment-body-data {
      padding: 8px;
      height: 120px;
      // width: 200px;
      border-radius: 3px;
      border: 0.5px solid #2fb16b;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
    }

    .comment-btn-text {
      font-size: 11px;
      font-weight: 400;
    }
  }

  .previous-comments {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 60%;
    .previous-comments-child {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;
      // border-top: 0.5px solid $border-color;

      .comment-username {
        font-size: 12px;
        font-weight: 400;
        line-height: 22.4px;
        color: black;
        width: fit-content;
        height: 23px;
      }

      .comment-user-img {
        width: 40px;
        height: 40px;
        border-radius: 100px;
      }

      .comment-text-data {
        text-align: justify;
        color: $text-color-light;
        font-size: 12px;
        font-weight: 400;
      }

      .comment-reply-btn {
        width: 50px;
        height: fit-content;
        color: $text-color;
        border-right: 0.5px solid $border-color;
        border-width: 2px;
        font-size: 10px;
        font-weight: 300;
        line-height: 14px;
        text-align: left;
      }

      .comment-time {
        // width: 50px;
        height: fit-content;
        color: $text-color-light;
        font-size: 11px;
        font-weight: 300;
        line-height: 16px;
        text-align: left;
      }
    }

    .no-comments {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      color: $text-color;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;

      .nc-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;
        color: $text-color;
      }

      .nc-text-2 {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        color: $text-color-light;
        width: 181px;
        height: 28px;
      }
    }
  }

  .previous-comments.no-data {
    justify-content: center;
    align-items: center;
  }
}
