.gradient-custom {
  /* fallback for old browsers */
  background: #075f33;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, #0df0ac, #075f33);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, #0df0ac, #075f33);
  .row-position {
    align-self: center;
    position: relative;
    top: -20px;

    .profile-edit {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
      margin-top: "";
      // margin-left: 40px;
    }
  }
}
