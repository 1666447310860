body {
  background-color: #f8f9fa;
}

.table {
  th,
  td {
    vertical-align: middle;
    text-align: center;
    background: transparent;
    font-family: Roboto Flex, sans-serif;
    padding : 16px 16px;
  }

  th {
    font-size: 14px;
    font-weight: 500;
  }

  .form-control {
    height: auto;
  }
}

.font-weight-bold {
  font-weight: 700;
  font-size: 14px;
}

.text-success {
  color: green !important;
}
