@import "../../assets/styles/global.scss";

.modal-xs .ReactModal__Content {
  width: 500px;
}

.logscomments {
  .heading1 {
    font-size: 20px;
    font-weight: bolder;
    // margin-bottom: 5px;
  }

  .comment-parent-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }

  .comment-child-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // border: $primary-color 1px solid;
    padding: 20px;
    margin: 0px;
    gap:5px;
    background-color: #f6f6f6;
  }

  .header-text {
    font-weight: bold;
    font-size: 16px;
    width: 100px;
  }
}

// .seprator {
//   height: 1px;
//   background-color: #e5e5e5;
//   margin: 10px 0;
// }

// .numbered-list {
//   list-style-type: decimal;
//   padding-left: 20px;
//   margin: 0;
//   font-size: 14px;
// }

// .numbered-list li {
//   margin: 5px 0;
//   font-size: 16px;
// }

.table-container-anim {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.5s ease-in-out forwards;

  &.loaded {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
