@import "../../assets/styles/global.scss";

.noc-project-summary {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    background-color: $primary-color;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: $color-white;
  }

  .body {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 5px 15px;

    .project-detail {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .project-detail-heading {
        // width: 94px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        color: $text-color;
      }

      .project-detail-data {
        height: 90%;
        border: 0.5px solid $border-color;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px 15px;

        .bold-header {
            width: 100px;
          font-weight: 400;
          color: $text-color;
          font-size: 14px;
        }

        .text-data {
          font-size: 14px;
          font-weight: 400;
          color: $text-color-light;
        }
      }
    }

    .project-report {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .project-report-heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        width: fit-content;
        height: 20px;
        color: $text-color;
      }

      .project-report-data {
      height:90%;
        border: 0.5px solid $border-color;
        border-radius: 4px;
      }

      .project-report-data-header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        border-bottom: 0.5px solid $border-color;
        border-radius: 4px;
        background-color: $primary-color-light;
        color: $text-color;
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        letter-spacing: 0.01em;
      }

      .project-report-data-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        color: $text-color;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: 0.01em;
        font-weight: bold;
      }
    }
  }
}
