@import "./../../assets/styles/global.scss";

.project-summary-col {
  border-right: 2px dashed $primary-color;
  font-size: 14px;
}

.projectTable {
  box-shadow: 0px 8px 15px #67748E24;
  padding: 10px 15px 25px;
  border-radius: 6px;
  position: relative;
  background-color: #fff;
}

.project-menu-wrapper {
  gap: 16px;
  margin-top: 45px;
  flex-wrap: wrap;

  .card {
    text-align: center;
    align-items: center;
    // border: none;
    width: 130px;
    padding: 30px 5px;
    box-shadow: 0px 3px 12px #0000002e;
    border-radius: 6px;
    margin-bottom: 15px;
    transition: 0.5s all;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 3px 15px rgba(228, 112, 30, 0.4);
      position: relative;
      transform: translateY(-10px);
    }

    img {
      max-width: 50px;
      height: 50px;
      font-size: 13px;
      cursor: pointer;
    }

    label {
      color: $primary-color;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 15px;
      margin-top: 5px;
      width: 85%;
      cursor: pointer;
    }
  }
}

.map-icon {
  width: 85px;
}

.externalSecondClass {
  margin-left: 10vw;
}

.album-wrapper {
  gap: 10px;

  .other-images {
    display: flex;
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      width: 150px;
      height: 150px;
    }

    .view-more-images {
      justify-content: center;
      align-items: center;
      width: 150px;
      display: flex;
      flex-direction: column;
      transition: 0.5s all;
      cursor: pointer;

      &:hover {
        background-color: #0075b638;
        box-shadow: 3px 3px 5px 1px rgba(167, 205, 237, 0.7);
      }
    }
  }
}


.add-filter-btns {
  background-color: white;
  padding: 8px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  color: #707070;
  cursor: pointer;
  margin: 0px 5px;
  font-size: 13px;

  // font-weight: bold;
  &:hover {
    background-color: #1C1D30;
    color: #fff;
  }
}

.upload-img {
  display: block;
  border-radius: 6px;

  .action-icons {
    cursor: pointer;
    font-size: 18px;
    margin: 10px 2px;
    color: $primary-color;

    &:hover {
      color: $primary-color-dark;
    }
  }
}


.upload-image-modal {
  .ReactModal__Content {
    overflow-y: auto;
    max-height: 600px;
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    color: $primary-color;
  }

  .action-icons {
    position: absolute;
    margin-right: 20px;
    right: 0;
    top: 30px;
    font-size: 20px;
    cursor: pointer;
    color: $primary-color;
    z-index: 9;
  }

  label {
    color: $primary-color;
    font-weight: bold;
  }
}


