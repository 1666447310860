@import "../../assets/styles/global.scss";
.header-documents{
    background-color: $primary-color;
    // padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

    .header-text{
        margin: 0;
        color: $color-white;
        font-size: 1rem;
        font-family: Roboto Flex,sans-serif;
        font-weight: 600;
    }
}