@import "../../../assets/styles/global.scss";

.inputTableFieldAuto td.input-wrap {
  min-width: auto !important;
}

.tb-input {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .tableInput {
    min-width: 100%;
    border-spacing: 3px;
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 4px;
    border-collapse: initial;

    thead {
      color: #777777;
    }

    tbody {
      background: $color-white;
    }

    tr {

      th,
      td {
        padding: 6px;

        span {
          cursor: pointer;
          padding: auto 0px;
        }
      }

      th {
        padding-bottom: 0;
      }

      td {
        color: #555;
        margin: 0px;
        border-collapse: collapse;
        padding: 2px 6px;
        vertical-align: top;
        vertical-align: middle;
        // min-width: 160px;

        p {
          width: 100%;
          display: flex;
          margin: 0;
        }

        input {
          width: 100%;
          display: flex;
          margin: 0;

          &:focus {
            background: $color-white;

          }
        }

        &.input-wrap {
          border-radius: 3px;
          padding: 2px;
          min-width: 160px;


        }

        &.act-wrap {
          width: 4rem;

          .act {
            min-height: 2.5rem;
            display: flex;
            justify-content: center;
            min-width: 3rem;
            gap: 4px;

            button {
              padding: 0px 12px;
              // margin-right: 8px;
              border-color: $primary-color;
              min-width: 2rem;
              font-size: 1em;
              border-radius: 4px;
              transition: 0.5s all;

              &:hover {
                background: $color-white;
              }
            }
          }
        }

        >div {
          min-width: 100px;
        }

        >div.act {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 150px;
        }
      }
    }

    // .dropdown-input {
    //   min-width: 100%;
    //   &[class*="-container"] {
    //     min-width: 16% !important;
    //     height: 20px;
    //     // position: absolute;
    //     width: fit-content !important;
    //   }
    // }

    .dropdown {
      input {
        min-width: 150px !important;
      }

      &[class*="-container"] {
        min-width: inherit !important;
        width: 165px !important;
      }

      & [class*="-control"] {
        width: initial !important;
      }
    }

    .css-14el2xx-placeholder {
      white-space: pre;
    }

    .add-more-btn {
      // display: flex;
      display: inline-flex;
      align-items: center;
      background: transparent;
      color: $primary-color;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      gap: 4px;
      font-family: Roboto Flex, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        font-size: 14px;
        font-weight: 700;
      }
    }

    >td {
      border-top: 1px solid $border-color;
    }
  }
}

@media (max-width: 1116px) {
  .tableInput {
    height: 150px;
  }

  .table-overflow {
    overflow: auto;
  }
}