

.header-profile {
  cursor: pointer;

  .profile-img-bg {
    display: flex;
    line-height: 17px;
    align-items: center;
    text-align: left;

    img {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      margin-right: 7px;
    }

    p {
      margin: 0px;
    }
  }

  .img-click-text {
    position: absolute;
    top: 46px;
    left: 100px;
    bottom: 0;
    right: 0px;
    z-index: 999;

    .profile-box {
      background-color: #fff;
      padding: 10px 0px;
      width: 159px;
      color: #000;
      // line-height: 10px;
      text-align: left;
      font-size: 14px;
      border: 1px solid #d1d1d1;
      border-radius: 5px;

      p {
        margin-bottom: 0px;
        cursor: pointer;
        padding: 5px 10px;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      hr {
        margin: 0px 0px;
      }
    }
  }
}

.link-with-icon {
  padding: 5px;
  font-size: 15px;

  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
}