// src/OfficeTable.scss
@import "../../assets/styles/global.scss";
.rd-tb {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  .office-table {
    min-width: 100%;
    th {
      font-family: Roboto Flex, sans-serif;
      font-size: 14px;
      color: black;
      padding-left: 16px;
      &.rounded-left {
        border-top-left-radius: 0.25rem;
      }

      &.rounded-right {
        border-top-right-radius: 0.25rem;
      }
    }

    td {
      font-family: Roboto Flex, sans-serif;
      font-size: 14px;
      color: $text-color-light;
      padding-left: 16px;
      &.no-border-left {
        border-left: none;
      }

      &.no-border-right {
        border-right: none;
      }
    }

    .top-header {
      background-color: $primary-color;
      font-size: 12px;
      color: $color-white;
      font-weight: 500;
      padding: 12px 16px;
    }
    .subActivity {
      font-family: Roboto Flex, sans-serif;
      font-size: 12px;
      color: black;
      // padding-left: 16px;
      &.rounded-left {
        border-top-left-radius: 0.25rem;
      }

      &.rounded-right {
        border-top-right-radius: 0.25rem;
      }
    }
  }
}

.table-label {
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  color: $text-color;
  font-weight: 700;
  margin: 6px 0;
}
