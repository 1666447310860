.main-content {
  min-height: 100vh;
  background: #F8F9FA;
  margin: 0px 0px 15px 0px;
  overflow: auto;
  padding: 0px 0px 24px 0px;
  width: calc(100% - 300px);
  @media (max-width: 920px) {
    width: calc(100% - 80px);
  }
}
.dashboard {
  width: 100%;
  background-color: white;
  padding: 2rem;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 2rem;
  .sub {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        width: 30rem;
      }
    }
    .right {
      span {
        margin-right: 1rem;
      }
    }
  }
}
