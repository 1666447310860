@import "../../assets/styles/global.scss";

.separator-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .separator-line {
    border-radius: 50px;
    background-color: $border-color;
  }