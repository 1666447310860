@import './colors.scss';

#root {
  // transition:.5s all;
  --bs-link-color: #0175b6;
}

.ReactModal__Body--open {
  #root {
    filter: grayscale(0.2) blur(2px);
  }
}

a {
  text-decoration: none !important;
  // color: $primary-color;
}

.ReactModal__Overlay {
  z-index: 99;

  .ReactModal__Content {
    z-index: 99;
    position: fixed !important;
    border-radius: 6px !important;
    margin: 0px auto;
    box-shadow: 1px 1px 4px 1px rgba(204, 204, 204, 0.6);
  }
}

.modal-sm {
  .ReactModal__Content {
    max-width: 800px;

  }
}

.modal-xs {
  .ReactModal__Content {
    max-width: 800px;
    height: 400px;

  }
}

.modal-lg {
  .ReactModal__Content {
    max-width: 1000px;
  }
}

.app-card {
  align-items: center;
  border: none;
  width: 100%;
  padding: 30px 5px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  transition: 0.5s all;
  cursor: pointer;
}



.indicator-target-input input {
  border-bottom: 1px solid #d2d2d2;
  padding: 7px;
  width: 100px !important;
}


.multi-select-view>input {
  margin: 0px 2px;
  border-bottom: 1px solid #a8a6a6;
}