@import "../../assets/styles/global.scss";
.table-wrapper {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dee2e6; // Add a border to the wrapper instead of the table

  .table {
    margin-bottom: 0; // Remove margin from the table to avoid spacing issues

    th,
    td {
      vertical-align: middle;
    }

    .thead-light {
      background-color: #f8f9fa;

      th {
        background-color: #f8f9fa;
        font-family: Roboto Flex, sans-serif;
        font-weight: 400;
        color: black;
        font-size: 14px;
        &.text-center {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          &.text-center {
            text-align: center;
          }

          input[type="checkbox"] {
            width: 20px;
            height: 20px;
            background-color: $primary-color-light;
            border-radius: 2px;
            border: 1px solid $border-color;
            &:checked {
              accent-color: $primary-color;
            }
          }
        }

        .td-description-txt {
          font-family: Roboto Flex, sans-serif;
          font-weight: 400;
          color: black;
          font-size: 14px;
        }
      }
    }
  }
}
.table-label {
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  color: $text-color;
  font-weight: 700;
  margin: 8px 0;
}