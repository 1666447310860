@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px); // Start above
  }
  100% {
    opacity: 1;
    transform: translateY(0); // Move to original position
  }
}

.animate-fade-in {
  animation: fadeInDown 0.5s ease-in-out; // Set the duration and easing for the animation
}

.collapse {
  // overflow: hidden; // Ensure no scrollbar appears during animation
}
