@import "../../../assets/styles/global.scss";
.switch {
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 12px 12px;
  margin: 8px 0;
  justify-content: space-between;

  p {
    margin: 0;
    font-family: Roboto Flex, sans-serif;
  }
}

.form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
  border: 1px solid $border-color;
}
.form-switch .form-check-input:checked {
  background-color: $primary-color;
}
