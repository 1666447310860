@import "../../assets/styles/global.scss";
.header {
  // margin-bottom: 30px;
  padding: 12px 16px;

  .input-search {
    gap: .5rem;
    padding: 4px;
    input {
      outline: none;
      border: none;
      font-size: 16px;
      font-family: Roboto Flex,sans-serif;
      width: 100%;
      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .right {
    display: flex;
    justify-content: space-between;

    .notif-wrapper {
      .icon {
        margin-right: 1rem;
        margin-top: 0.5rem;

        span {
          position: relative;
          z-index: 9;
          top: -10px;
        }

      }

      .notification-icon{
        p{
          margin: 0px 2px 0px 0px;
          background-color: $primary-color;
          color: white;
          border-radius: 8px;
          width: 36px;
          height: 16px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          margin-top: -10px;
          margin-left: -10px;
        }
      }
    }

    .dropdown {
      .menu-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .menu-trigger {
        background: #ffffff;
        border-radius: 2rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border: none;
        vertical-align: middle;
        transition: box-shadow 0.4s ease;
        margin-left: auto;
        /* Strictly for positioning */
      }

      .menu-trigger:hover {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
      }

      .menu-trigger span {
        font-weight: 700;
        vertical-align: middle;
        font-size: 14px;
        margin: 0 10px;
      }

      .menu-trigger img {
        border-radius: 90px;
      }

      .menu {
        background: #ffffff;
        border-radius: 8px;
        position: absolute;
        top: 60px;
        right: 0;
        width: 300px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
      }

      .menu.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu li {
        border-bottom: 1px solid #dddddd;
      }

      .menu li a {
        text-decoration: none;
        color: #333333;
        padding: 15px 20px;
        display: block;
      }
    }
  }
}

.header-profile {
  cursor: pointer;

  .profile-img-bg {
    display: flex;
    line-height: 17px;
    align-items: center;
    text-align: left;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 7px;
    }

    p {
      margin: 0px;
    }
  }

  .img-click-text {
    position: absolute;
    top: 46px;
    left: 100px;
    bottom: 0;
    right: 0px;
    z-index: 999;

    .profile-box {
      background-color: #fff;
      padding: 10px 0px;
      width: 159px;
      color: #000;
      // line-height: 10px;
      text-align: left;
      font-size: 14px;
      border: 1px solid #d1d1d1;
      border-radius: 5px;

      p {
        margin-bottom: 0px;
        cursor: pointer;
        padding: 5px 10px;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      hr {
        margin: 0px 0px;
      }
    }
  }
}
