@import "../../../assets/styles/global.scss";

body {
  background-color: #f8f9fa;
}

.container {
  margin-top: 20px;
  //   overflow-x: auto;
}

table {
  th,
  td {
    vertical-align: middle;
    padding: 0.75rem;
    // min-width: 165px;
  }
}

button {
  margin-right: 10px;
}



.table{
  .input-form-fields{
    background-color: "#f8f9fa";
    border: none;
    outline: none;
    width:100%;
    // font-size: 12px;

    &:focus{
      box-shadow: none;
    }
  }
}