@import "../../../assets//styles/global.scss";
.project-des {
    .label{
        font-family: Roboto Flex, sans-serif;
        font-size: 14px;
        color: $text-color;
        font-weight: 700;
    }
  .header-name {
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid $border-color;
    padding: 12px 16px;
    p:nth-child(1) {
      margin: 0;
      font-family: Roboto Flex, sans-serif;
      font-size: 14px;
      color: $text-color;
    }
    p {
      margin: 0;
      font-family: Roboto Flex, sans-serif;
      font-size: 14px;
    }
  }

  .label-heading {
    font-family: Roboto Flex, sans-serif;
    font-size: 14px;
    color: $text-color;
    font-weight: 700;
    margin: 8px 0;
  }
}

