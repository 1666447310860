@import "../../assets/styles/global.scss";

.monthly-progress-report {
  height: 320px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 10px;
  border-radius: 6px;
  border: 0.8px solid $border-color;
  font-family: Roboto Flex, sans-serif;

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    text-align: left;
    padding: 5px;
  }
  .title-icon {
    text-align: right;
    height: 20px;
  }

  .main {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    gap: 5px;
    border: 1px soild black;
    align-items: center;
    overflow-y: auto;
    padding-top: 10px;
    -ms-overflow-y-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .main-child {
    display: flex;
    flex-direction: row;
    background-color: $primary-color-light;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 2px;
    padding: 10px;
  }
  .heading {
    font-size: 12px;
    font-weight: 400;
    color: $text-color;
    padding-left: 5px;
    justify-content: end;
  }

  .date {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #9c9faa;
    padding-left: 5px;
  }

  .icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 4px;
  }

  .status {
    width: 180px;
    height: 5px;
    border-radius: 8px;
  }
}
