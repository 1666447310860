@import "./assets/styles/global.scss";
* {
  box-sizing: border-box;
  list-style: none;
}

a:hover {
  color: $link-color;
}

.App {
  color: #67748e;
  background: #f8f9fa;
  max-width: 2560px;
  font-family: Roboto Flex;
  margin: 0 auto;
}
.br-input {
  padding: 8px;
  border-radius: 7px;
  border: solid 1px #f6fafe;
  background: #eef5fd;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-behaviour {
  cursor: pointer;
}
.w100 {
  width: 100%;
}
.flex {
  display: flex;
  &.justify-content-between {
    justify-content: space-between;
  }
}
.p-2 {
  padding: 10px;
}

.t-input {
  outline: none;
  border: none;
  background: transparent;

  &:focus {
    box-shadow: none;
  }
}

.bg-lb {
  background: #eef5fd;
}

.action-icons {
  margin-right: 10px;
  right: 0;
  font-size: 16px;
  cursor: pointer;
  color: $primary-color;
}

.action-icons:hover {
  color: $link-color;
}

.bg-orange {
  background: $secondary-color;
}

.bg-orange-light {
  // background:$secondary-color-light
}

.bg-light-grey {
  background: $light-gray-color;
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.8s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-1-27 17:24:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.heading1 {
  font-size: 1.125rem;
  color: $text-color;
  font-family: Roboto Flex, sans-serif;
  font-weight: 600;
}
.heading2 {
  font-size: 1rem;
  color: $text-color;
  font-family: Roboto Flex, sans-serif;
  font-weight: 600;
}
.heading3 {
  font-size: 0.875rem;
  color: $text-color;
  font-family: Roboto Flex, sans-serif;
  font-weight: 600;
}

.heading4 {
  font-size: 0.75rem;
  color: $text-color;
  font-family: Roboto Flex, sans-serif;
  font-weight: 600;
}

.text-regular {
  font-size: 0.875rem;
  color: $text-color;
  font-family: Roboto Flex, sans-serif;
  font-weight: 400;
}


.table-item-hover{
  &:hover{
    color: $primary-color;
    font-weight: 600;
  }
}