@import "../../../assets/styles/global.scss";
.radio-op {
  margin: 8px 0;
  input[type="radio"] {
    width: 16px;
    height: 16px;
    margin: 0px;

    &:checked {
      accent-color: $primary-color;
    }

    &:focus {
      box-shadow: none;
    }
  }

  p {
    font-family: Roboto Flex, sans-serif;
  }

  .radio-row-container {
    gap: 24px;
  }
  .radio-container {
    gap: 8px;
  }
}
