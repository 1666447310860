@import "../../assets/styles/global.scss";

.chart {
    padding: 15px 10px;
    border-radius: 6px;
    // border: 0.8px solid $border-color;
    
  }
  .chart-title {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 5px ;
  }

