@import "../../../assets/styles/global.scss";
.checkbox {
  display: flex !important;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 12px;
  margin: 8px 0;
  justify-content: space-between;

  p {
    margin: 0;
    font-family: Roboto Flex, sans-serif;
  }

  input {
    margin: 0;
    width: 28px;
    height: 28px;

    &:checked {
      accent-color: $primary-color;
      background-color: $primary-color;
    }
  }
  &.checked {
    border-color: $primary-color;
  }
  .form-check-input {
    border: 1px solid #ccc;
    .checked {
      border-color: $primary-color;
    }
  }
}
